import './axios-interceptors';
import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  isUnauthorized,
  getWorld as _getWorld,
  listWorlds as _listWorlds,
  startWorld as _startWorld,
  stopWorld as _stopWorld,
  saveServerOptions as _saveServerOptions,
  createWorld as _createWorld,
  deleteWorld as _deleteWorld,
  getUsersByGamertags as _getUsersByGamertags,
} from './worlds';
import { getMyProfile as _getMyProfile, updateMyProfile as _updateMyProfile } from './profile';

const supportedEditionsAndVersions = {
  editions: [
    {
      name: 'java',
      versions: [{ number: '1.20.1' }, { number: '1.19.4' }, { number: '1.19.3' }, { number: '1.19.2' }],
    },
    {
      name: 'bedrock',
      versions: [
        { number: '1.20.11' },
        { number: '1.20.1' },
        { number: '1.19.73' },
        { number: '1.19.72' },
        { number: '1.19.62' },
        { number: '1.19.60' },
        { number: '1.19.51' },
      ],
    },
  ],
};

export type Bff = {
  getWorld: typeof _getWorld;
  listWorlds: typeof _listWorlds;
  startWorld: typeof _startWorld;
  stopWorld: typeof _stopWorld;
  saveServerOptions: typeof _saveServerOptions;
  createWorld: typeof _createWorld;
  deleteWorld: typeof _deleteWorld;
  getUsersByGamertags: typeof _getUsersByGamertags;
  getMyProfile: typeof _getMyProfile;
  updateMyProfile: typeof _updateMyProfile;
  getSupportedEditionsAndVersions: () => Promise<{
    status: number;
    data: typeof supportedEditionsAndVersions;
  }>;
};

export const useBff = (): Bff => {
  const navigate = useNavigate();

  const [navigateToSignIn, setNavigateToSignIn] = useState(false);

  useEffect(() => {
    if (navigateToSignIn) {
      navigate('/signin');
    }
  }, [navigate, navigateToSignIn]);

  const redirectIfUnauthorized = useCallback(async <T, R>(responsePromise: Promise<AxiosResponse<T, R>>) => {
    return responsePromise
      .then((res) => res)
      .catch((err) => {
        if (isUnauthorized(err)) {
          setNavigateToSignIn(true);
        }
        throw err;
      });
  }, []);

  const getWorld: typeof _getWorld = useCallback(
    async (
      name: string,
      options?: {
        state?: boolean;
        serverProperties?: boolean;
        sharings?: boolean;
      }
    ) => {
      return redirectIfUnauthorized(_getWorld(name, options));
    },
    [redirectIfUnauthorized]
  );

  const listWorlds: typeof _listWorlds = useCallback(
    async (options?: { sharedWithMe?: boolean }) => {
      return redirectIfUnauthorized(_listWorlds(options));
    },
    [redirectIfUnauthorized]
  );

  const startWorld: typeof _startWorld = useCallback(
    async (name: string) => {
      return redirectIfUnauthorized(_startWorld(name));
    },
    [redirectIfUnauthorized]
  );

  const stopWorld: typeof _stopWorld = useCallback(
    async (name: string) => {
      return redirectIfUnauthorized(_stopWorld(name));
    },
    [redirectIfUnauthorized]
  );

  const saveServerOptions: typeof _saveServerOptions = useCallback(
    async (
      name: string,
      options: {
        version?: string;
        serverProperties?: any;
        sharings?: { gamertags: string }[];
      }
    ) => {
      return redirectIfUnauthorized(_saveServerOptions(name, options));
    },
    [redirectIfUnauthorized]
  );

  const createWorld: typeof _createWorld = useCallback(
    async (name: string, edition: string, version: string) => {
      return redirectIfUnauthorized(_createWorld(name, edition, version));
    },
    [redirectIfUnauthorized]
  );

  const deleteWorld: typeof _deleteWorld = useCallback(
    async (name: string) => {
      return redirectIfUnauthorized(_deleteWorld(name));
    },
    [redirectIfUnauthorized]
  );

  const getUsersByGamertags = useCallback(
    async (gamertags: string[]) => {
      return redirectIfUnauthorized(_getUsersByGamertags(gamertags));
    },
    [redirectIfUnauthorized]
  );

  const getMyProfile = useCallback(async () => {
    return redirectIfUnauthorized(_getMyProfile());
  }, [redirectIfUnauthorized]);

  const updateMyProfile = useCallback(
    async ({ gamertag }: { gamertag: string }) => {
      return redirectIfUnauthorized(_updateMyProfile({ gamertag }));
    },
    [redirectIfUnauthorized]
  );

  const getSupportedEditionsAndVersions: Bff['getSupportedEditionsAndVersions'] = useCallback(async () => {
    const response = {
      status: 200,
      data: supportedEditionsAndVersions,
    };
    return response;
  }, []);

  return {
    getWorld,
    listWorlds,
    startWorld,
    stopWorld,
    saveServerOptions,
    createWorld,
    deleteWorld,
    getUsersByGamertags,
    getMyProfile,
    updateMyProfile,
    getSupportedEditionsAndVersions,
  };
};

export default useBff;
